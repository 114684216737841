<template>
  <div>
    <div class="createText">
      添加跟进
    </div>
    <div class="create flex column flex-center">
      <div class="create-way flex flex-center">
        <div>跟进方式</div>
        <div
          class="create-way_right flex flex-center"
          @click="prizeVisible = true"
        >
          {{ value || "跟进方式选择" }}
          <img
            class="create-way_triangle"
            src="../assets/imgs/triangle.png"
            alt=""
          />
        </div>
      </div>
      <div class="create-text">
        <div class="create-text_title">跟进内容</div>
        <textarea placeholder="请输入跟进内容" v-model="content"></textarea>
      </div>
      <div class="create-enclosure">上传附件</div>

      <div class="create-all">
        <div class="create-add" @click="triggerUpload" v-if="!img_url"></div>
        <input
          v-if="!img_url"
          id="filePhoto"
          type="file"
          ref="file"
          accept="image/*"
          hidden
          @change="onInputChange($event)"
        />

        <div class="rightullidiv" v-if="img_url">
          <img :src="img_url" alt="" class="rightulliimg" />
        </div>
      </div>
      <div class="padding-b"></div>
      <!-- 底部固定 -->
      <div class="bottom fixed flex">
        <div class="bottom_cancel flex-item_center" @click="back()">取消</div>
        <div class="bottom_determine flex-item_center" @click="confirm()">
          确定
        </div>
      </div>
    </div>

    <VuePicker
      :data="list"
      :showToolbar="true"
      @cancel="prizeVisible = false"
      @confirm="Prizeconfirm"
      :visible.sync="prizeVisible"
    />
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";
import VuePicker from "vue-pickers";
import axios from "axios";
import config from "@/config";

export default {
  components: { VuePicker },
  data() {
    return {
      page: 1,
      prizeVisible: false,
      list: [],
      type_id: "",
      value: "",
      content: "",
      img_url: "",
      confirmDisable: true,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    back() {
      this.$router.back();
    },
    async confirm() {
      let that = this;
      if (this.type_id == "") {
        Toast("请选择跟进方式");
        return false;
      }

      if (this.content == "") {
        Toast("请输入内容");
        return false;
      }

      if (this.img_url == "") {
        Toast("请上传图片");
        return false;
      }

      let res = await request._addFollow({
        userid: localStorage.getItem("userid"),
        contact_id: this.$route.query.external_userid,
        type_id: this.type_id,
        content: this.content,
        img_url: this.img_url,
      });
      if (_.toInteger(res.code) === 1) {
        Toast.success("添加成功");
        setTimeout(() => {
          this.$router.back();
        }, 1300);
      }
    },
    Prizeconfirm(res) {
      console.log("res", res);
      this.type_id = res[0].value;
      this.value = res[0].label;
      // this.$set(this.postData[this.index], "prize_id", res[0].value);
    },
    async getList() {
      let res = await request._getFollow({
        userid: localStorage.getItem("userid"),
        contact_id: this.$route.query.external_userid,
        page: this.page,
      });
      console.log("跟进", res);
      if (_.toInteger(res.code) === 1) {
        var result = res.data;
        var arr = [];
        result.forEach((item) => {
          arr.push({
            label: item.title,
            value: item.id,
          });
        });
        this.list = [arr];
      }
    },
    //点击图片触发文件上传
    triggerUpload() {
      $("#filePhoto").click();
    },

    //文件上传到浏览器触发事件
    async onInputChange(el) {
      var that = this;
      var file = el.target.files[0];
      console.log(file);
      var type = file.type.split("/")[0];

      let formData = new FormData();
      formData.append("file", file);
      console.log(formData);
      // let res = await request._uploadImg(formData);
      axios({
        method: "post",
        url: config.baseUrl.dev + "workapi/mobile.User/upload",
        data: formData,
      }).then(
        (res) => {
          console.log("Res", res);
          if (res.data.code == 1) {
            that.img_url = res.data.data.img_url;
          }
        },

        (err) => {}
      );
    },
  },
};
</script>

<style scoped>
.rightullidiv {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  align-items: center;
  padding-bottom: 260px;
}

.rightulliimg {
  max-width: 100%;
  max-height: 700px;
}

.createText {
  font-size: 40px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  padding: 43px 0 36px 30px;
}

.create {
  width: 100%;
  height: 1315px;
  background: #ffffff;
  border-radius: 36px 36px 0px 0px;
}

.create-way {
  padding: 66px 0 26px 0;
  width: 670px;
  border-bottom: 1px solid #ededf7;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  justify-content: space-between;
}

.create-way_right {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.create-text {
  padding: 66px 0 26px 0;
  width: 670px;
  border-bottom: 1px solid #ededf7;
}

.create-text_title {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  margin-bottom: 36px;
}

textarea {
  width: 100%;
  height: 300px;
  border: 0;
  outline: none;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #101f48;
}

.create-enclosure {
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #66728f;
  width: 670px;
  padding: 52px 0 0 0;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom_cancel {
  width: 325px;
  height: 80px;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 2px solid #0558f4;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #0558f4;
  margin-top: 15px;
}

.bottom_determine {
  width: 325px;
  margin-top: 15px;

  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-left: 20px;
}

.create-way_triangle {
  margin-left: 10px;
  width: 12px;
  height: 8px;
}

.create-add {
  width: 180px;
  height: 180px;
  background: url("../assets/imgs/add.png");
  background-size: 100% 100%;
  border-radius: 16px 16px 16px 16px;
  margin-top: 37px;
}

.create-all {
  width: 670px;
}
</style>
